export const BEGIN_API_CALL = "BEGIN_API_CALL";
export const API_CALL_ERROR = "API_CALL_ERROR";

export const GET_LOGIN_STATUS = "GET_LOGIN_STATUS";
export const GET_SITES_LIST = "GET_SITES_LIST";
export const GET_TABS_AND_MENUS = "GET_TABS_AND_MENUS";
export const TOGGLE_CREATE_ACCOUNT = "TOGGLE_CREATE_ACCOUNT";
export const TOGGLE_EULA = "TOGGLE_EULA";
export const TOGGLE_PASSWORD_RESET = "TOGGLE_PASSWORD_RESET";
export const SET_WORKORDER_ATTACHMENT_STATUS = "SET_WORKORDER_ATTACHMENT_STATUS";
export const SET_WORKORDER_INITIAL_COOKIE_VALUES = "SET_WORKORDER_INITIAL_COOKIE_VALUES";
export const SET_CLIENT_IS_PHONE = "SET_CLIENT_IS_PHONE";

// The CLEAR_ types are internal events used to update state, that may have been populated, back to default state
export const CLEAR_COPY_CONTENT = "CLEAR_COPY_CONTENT";
export const CLEAR_EMPLOYEE_PROFILE = "CLEAR_EMPLOYEE_PROFILE";
export const CLEAR_EQUIPMENT = "CLEAR_EQUIPMENT";
export const CLEAR_EQUIPMENT_CONTENT = "CLEAR_EQUIPMENT_CONTENT";
export const CLEAR_EQUIPMENT_DETAIL = "CLEAR_EQUIPMENT_DETAIL";
export const CLEAR_EQUIPMENT_MACHINE_SERVICE_HISTORY = "CLEAR_EQUIPMENT_MACHINE_SERVICE_HISTORY";
export const CLEAR_EQUIPMENT_SERVICE_CALL_DETAIL = "CLEAR_EQUIPMENT_SERVICE_CALL_DETAIL";
export const CLEAR_MAILROOM_CONTENT = "CLEAR_MAILROOM_CONTENT";
export const CLEAR_MAILROOM_CONTENT_DETAIL = "CLEAR_MAILROOM_CONTENT_DETAIL";
export const CLEAR_MANAGEMENT_CONTENT = "CLEAR_MANAGEMENT_CONTENT";
export const CLEAR_RECORDS_CONTENT = "CLEAR_RECORDS_CONTENT";
export const CLEAR_SITE_MANAGEMENT = "CLEAR_SITE_MANAGEMENT";
export const CLEAR_SITE_SENIOR_MANAGEMENT = "CLEAR_SITE_SENIOR_MANAGEMENT";
export const CLEAR_TEAM_CONTENT = "CLEAR_TEAM_CONTENT";
export const CLEAR_WORKORDER = "CLEAR_WORKORDER";
export const CLEAR_WORKORDER_ATTACHMENTS = "CLEAR_WORKORDER_ATTACHMENTS";

// By convention, actions that end in "_SUCCESS" are assumed to have been the result of a completed API call.
// All calls are marshalled through the apiStatusReducer, which checks the suffix of the type, incrementing and
//  decrementing the count of open remove API calls in progress. This count, in turn, is used to show or hide
//  the loading indicator
export const ADD_SERVICE_CALL_SUCCESS = "ADD_SERVICE_CALL_SUCCESS";
export const ADD_UPDATE_WORKORDER_SUCCESS = "ADD_UPDATE_WORKORDER_SUCCESS";
export const CREATE_LOGIN_SUCCESS = "CREATE_LOGIN_SUCCESS";
export const DECREMENT_API_CALLS_IN_PROGRESS_SUCCESS = "DECREMENT_API_CALLS_IN_PROGRESS_SUCCESS";
export const GET_COPY_CONTENT_SUCCESS = "GET_COPY_CONTENT_SUCCESS";
export const GET_EMPLOYEE_PROFILE_SUCCESS = "GET_EMPLOYEE_PROFILE_SUCCESS";
export const GET_EQUIPMENT_MACHINES_SUCCESS = "GET_EQUIPMENT_MACHINES_SUCCESS";
export const GET_EQUIPMENT_MACHINE_SERVICE_HISTORY_SUCCESS = "GET_EQUIPMENT_MACHINE_SERVICE_HISTORY_SUCCESS";
export const GET_EQUIPMENT_SERVICE_CALLS_SUCCESS = "GET_EQUIPMENT_SERVICE_CALLS_SUCCESS";
export const GET_EQUIPMENT_SERVICE_CALL_DETAIL_SUCCESS = "GET_EQUIPMENT_SERVICE_CALL_DETAIL_SUCCESS";
export const GET_EULA_SUCCESS = "GET_EULA_SUCCESS";
export const GET_IS_AUTHENTICATED_SUCCESS = "GET_IS_AUTHENTICATED_SUCCESS";
export const GET_LOGIN_SUCCESS = "GET_LOGIN_SUCCESS";
export const GET_MAILROOM_CONTENT_SUCCESS = "GET_MAILROOM_CONTENT_SUCCESS";
export const GET_MAILROOM_DETAIL_SUCCESS = "GET_MAILROOM_DETAIL_SUCCESS";
export const GET_RECORDS_CONTENT_SUCCESS = "GET_RECORDS_CONTENT_SUCCESS";
export const GET_REPORTS_SUCCESS = "GET_REPORTS_SUCCESS";
export const GET_SITE_MANAGEMENT_SUCCESS = "GET_SITE_MANAGEMENT_SUCCESS";
export const GET_SITE_SENIOR_MANAGEMENT_SUCCESS = "GET_SITE_SENIOR_MANAGEMENT_SUCCESS";
export const GET_TEAM_SUCCESS = "GET_TEAM_SUCCESS";
export const GET_WORKORDER_ATTACHMENTS_SUCCESS = "GET_WORKORDER_ATTACHMENTS_SUCCESS";
export const GET_WORKORDERS_SUCCESS = "GET_WORKORDERS_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const SUBMIT_JOT_FORM_SUCCESS = "SUBMIT_JOT_FORM_SUCCESS";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const RESET_CHANGE_PASSWORD_SUCCESS = "RESET_CHANGE_PASSWORD_SUCCESS";
