export const mailroomTypes = {
  CERTIFIED_MAIL_ALL: "certifiedMailAll",
  CERTIFIED_MAIL_DETAIL: "certifiedMailDetail",
  CERTIFIED_MAIL_USER: "certifiedMailUser",
  COURT_FILINGS_ALL: "courtFilingsAll",
  COURT_FILINGS_USER: "courtFilingsUser",
  MAIL_SORT_VOLUMES: "mailSortVolumes",
  METER_READS: "meterReads",
  PACKAGES_ALL: "packagesAll",
  PACKGE_DETAIL: "packageDetail",
  PACKAGES_SEARCHED: "packgesSearched",
  PACKAGES_USER: "packagesUser",
};

export const mailroomLabels = {
  "Certified Mail": mailroomTypes.CERTIFIED_MAIL_ALL,
  "Court Filings": mailroomTypes.COURT_FILINGS_ALL,
  "Mail Machine Meter Reads": mailroomTypes.METER_READS,
  "Mail Sort Volumes": mailroomTypes.MAIL_SORT_VOLUMES,
  "My Certified Mail": mailroomTypes.CERTIFIED_MAIL_USER,
  "My Court Filings": mailroomTypes.COURT_FILINGS_USER,
  "My Packages": mailroomTypes.PACKAGES_USER,
  "Package History": mailroomTypes.PACKAGES_ALL,
};

export const mailroomDisplayLabel = {
  certifiedMailAll: "Certified Mail",
  certifiedMailUser: "My Certified Mail",
  courtFilingsAll: "Court Filings",
  courtFilingsUser: "My Court Filings",
  mailSortVolumes: "Mail Sort Volumes",
  meterReads: "Mail Machine Meter Reads",
  packagesAll: "Package History",
  packagesUser: "My Packages",
};
