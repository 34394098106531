import { handleResponse, handleError } from "./apiUtils";

const AUTHENTICATE_CFC_LOCATION = "cfc/authenticate";

let _token = "";

function getRandomStr() {
  return new Date().getTime().toString();
}

export function setToken(token) {
  _token = token + "&";
}

export const getBaseCfcLocalUrl = (method, file, path) => {
  const cfcFile = file || "authenticate";

  let baseCfcLocal = process.env.CF_URL + `${cfcFile}.cfc?${_token}method=${method}&randomStr=${getRandomStr()}`;
  if (path) {
    baseCfcLocal = `${path}?${_token}method=${method}&randomStr=${getRandomStr()}`;
  }

  return baseCfcLocal;
};

export function getCaptcha() {
  return fetch(getBaseCfcLocalUrl("getCaptcha", AUTHENTICATE_CFC_LOCATION), {
    method: "POST",
  })
    .then(handleResponse)
    .catch(handleError);
}

export function changePasswordFromEmail(sec_user_email, temporary_password, sec_user_password, captchaText) {
  let formData = new FormData();
  formData.append("sec_user_email", sec_user_email);
  formData.append("temporary_password", temporary_password);
  formData.append("sec_user_password", sec_user_password);
  formData.append("captchaText", captchaText);

  return fetch(getBaseCfcLocalUrl("changePasswordFromEmail", AUTHENTICATE_CFC_LOCATION), {
    method: "POST",
    body: formData,
  })
    .then(handleResponse)
    .catch(handleError);
}

export function changePassword(dataItem) {
  let formData = new FormData();
  formData.append("form_data", JSON.stringify({ from_where: "change_pw", ...dataItem }));

  return fetch(getBaseCfcLocalUrl("changePassword", AUTHENTICATE_CFC_LOCATION), {
    method: "POST",
    body: formData,
  })
    .then(handleResponse)
    .catch(handleError);
}
