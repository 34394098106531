import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function equipmentServiceCallDetailReducer(state = initialState.equipmentServiceCallDetail, action) {
  switch (action.type) {
    case types.GET_EQUIPMENT_SERVICE_CALL_DETAIL_SUCCESS:
      return { serviceDetail: action.equipmentServiceCallDetail };
    case types.CLEAR_EQUIPMENT_SERVICE_CALL_DETAIL:
      return {};
    default:
      return state;
  }
}
