import { handleResponse, handleError } from "./apiUtils";
import { mailroomTypes, mailroomLabels } from "../constants/mailroomTypes";

/* API for Mailroom */
let _token = "";

const END_USER_URL = process.env.CF_URL + "EndUserJS.cfc?";
const DOWNLOAD_FILE_URL = process.env.CF_URL + "show_report_json.cfm?";

/*  IE tends to cache content, so subsequent calls may return the same data. 
    This is intended as a cache breaker */
function getRandomStr() {
  return new Date().getTime().toString();
}

export function setToken(token) {
  _token = token + "&";
}

export function getMailroomContent(params) {
  if (Object.prototype.hasOwnProperty.call(mailroomLabels, params)) {
    switch (mailroomLabels[params]) {
      case mailroomTypes.CERTIFIED_MAIL_ALL:
      case mailroomTypes.CERTIFIED_MAIL_USER: {
        let urlParams = JSON.stringify({
          type: mailroomLabels[params],
        });
        return fetch(
          END_USER_URL +
            _token +
            "method=getCertifiedMailJS&certifiedType=" +
            btoa(unescape(encodeURIComponent(urlParams))) +
            "&randomStr=" +
            getRandomStr()
        )
          .then(handleResponse)
          .catch(handleError);
      }
      case mailroomTypes.CERTIFIED_MAIL_DETAIL: {
        let urlParams = JSON.stringify({
          MSPTCertifiedMail_id: params.MSPTCertifiedMail_id,
        });
        return fetch(
          END_USER_URL +
            _token +
            "method=getCertifiedMailDetailJS&detailArg=" +
            btoa(unescape(encodeURIComponent(urlParams))) +
            "&randomStr=" +
            getRandomStr()
        )
          .then(handleResponse)
          .catch(handleError);
      }
      case mailroomTypes.COURT_FILINGS_ALL:
      case mailroomTypes.COURT_FILINGS_USER: {
        let urlParams = JSON.stringify({
          type: mailroomLabels[params],
        });
        return fetch(
          END_USER_URL +
            _token +
            "method=getCourtFilingsJS&filingsType=" +
            btoa(unescape(encodeURIComponent(urlParams))) +
            "&randomStr=" +
            getRandomStr()
        )
          .then(handleResponse)
          .catch(handleError);
      }
      case mailroomTypes.MAIL_SORT_VOLUMES: {
        return fetch(END_USER_URL + _token + "method=getMailSortVolumeJS&randomStr=" + getRandomStr())
          .then(handleResponse)
          .catch(handleError);
      }
      case mailroomTypes.METER_READS: {
        return fetch(END_USER_URL + _token + "method=getMailMachineMeterReadingsJS&randomStr=" + getRandomStr())
          .then(handleResponse)
          .catch(handleError);
      }
      case mailroomTypes.PACKAGES_ALL:
      case mailroomTypes.PACKAGES_USER: {
        let urlParams = JSON.stringify({
          type: mailroomLabels[params],
        });
        return fetch(
          END_USER_URL +
            _token +
            "method=getPackagesJS&packagesType=" +
            btoa(unescape(encodeURIComponent(urlParams))) +
            "&randomStr=" +
            getRandomStr()
        )
          .then(handleResponse)
          .catch(handleError);
      }
      case mailroomTypes.PACKGE_DETAIL: {
        let urlParams = JSON.stringify({
          internalID: params.internalID,
        });
        return fetch(
          END_USER_URL +
            _token +
            "method=getPackageDetailJS&detailArg=" +
            btoa(unescape(encodeURIComponent(urlParams))) +
            "&randomStr=" +
            getRandomStr()
        )
          .then(handleResponse)
          .catch(handleError);
      }
      case mailroomTypes.PACKAGES_SEARCHED: {
        let packagesSearchedParams = JSON.stringify({
          internalId: params.internalId,
          trackingNumber: params.trackingNumber,
          carrier: params.carrier,
          sender: params.sender,
          receiver: params.receiver,
          beginDate: params.beginDate,
          endDate: params.endDate,
          myPackagesOnly: params.myPackagesOnly,
        });
        return fetch(
          END_USER_URL +
            _token +
            "method=getPackagesSearchedJS&searchedArg=" +
            btoa(unescape(encodeURIComponent(packagesSearchedParams))) +
            "&randomStr=" +
            getRandomStr()
        )
          .then(handleResponse)
          .catch(handleError);
      }

      default:
      // console.log(params.contentType + " not found");
    }
  }
}

export function getMailroomContentDetail(params) {
  if (Object.prototype.hasOwnProperty.call(params, "barrel_stamp_date")) {
    // certifiedMail detail
    let urlParams = JSON.stringify({
      MSPTCertifiedMail_id: params.msptcertifiedmail_id,
    });
    return fetch(
      END_USER_URL +
        _token +
        "method=getCertifiedMailDetailJS&detailArg=" +
        btoa(unescape(encodeURIComponent(urlParams))) +
        "&randomStr=" +
        getRandomStr()
    )
      .then(handleResponse)
      .catch(handleError);
  } else if (Object.prototype.hasOwnProperty.call(params, "cptdate")) {
    // packages detail
    let urlParams = JSON.stringify({
      internalid: params.internalid,
    });
    return fetch(
      END_USER_URL +
        _token +
        "method=getPackageDetailJS&detailArg=" +
        btoa(unescape(encodeURIComponent(urlParams))) +
        "&randomStr=" +
        getRandomStr()
    )
      .then(handleResponse)
      .catch(handleError);
  }
}

export function createSqlReport(params) {
  let urlParams = JSON.stringify(params);
  return fetch(
    END_USER_URL +
      _token +
      "method=printReportJS&contentVO=" +
      btoa(unescape(encodeURIComponent(urlParams))) +
      "&randomStr=" +
      getRandomStr()
  )
    .then(handleResponse)
    .catch(handleError);
}

export function openLocation(args) {
  // gets a file using show_report_json.cfm and the encrypted file name and path
  if (args.type === "path") {
    window.open(args.value + "?" + _token, "_blank");
  } else if (args.type === "pdf") {
    // submit for report
    let _args = { type: "path", value: btoa(unescape(encodeURIComponent(args.location))) };
    window.open(
      DOWNLOAD_FILE_URL +
        _token +
        "args=" +
        btoa(unescape(encodeURIComponent(JSON.stringify(_args)))) +
        "&randomStr=" +
        getRandomStr(),
      "_blank"
    );
  }
}
