import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function eulaReducer(state = initialState.eula, action) {
  switch (action.type) {
    case types.GET_EULA_SUCCESS:
      return action.result.eula;
    default:
      return state;
  }
}
