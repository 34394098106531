import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function managementContentReducer(state = initialState.managementContent, action) {
  switch (action.type) {
    case types.GET_REPORTS_SUCCESS:
      return action.managementContent;
    case types.CLEAR_MANAGEMENT_CONTENT:
      return initialState.managementContent;
    default:
      return state;
  }
}
