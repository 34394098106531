import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function authenticationReducer(state = initialState.authenticate, action) {
  switch (action.type) {
    case types.GET_IS_AUTHENTICATED_SUCCESS:
    case types.GET_LOGIN_SUCCESS:
    case types.LOGOUT_SUCCESS:
      return action.authenticate;
    case types.GET_LOGIN_STATUS:
      return { sec_user_valid: action.sec_user_valid, ...state };
    default:
      return state;
  }
}
