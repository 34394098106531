import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function recordsContentReducer(state = initialState.recordsContent, action) {
  switch (action.type) {
    case types.GET_RECORDS_CONTENT_SUCCESS:
      return action.result;
    case types.CLEAR_RECORDS_CONTENT:
      return initialState.recordsContent;
    default:
      return state;
  }
}
