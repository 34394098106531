import { handleResponse, handleError } from "./apiUtils";
import { managementFiles } from "../constants/managementTypes";
import { teamTypes } from "../constants/teamTypes";
import { copyTypes } from "../constants/copyTypes";

/*  API for Management, IST Team, Copy Center */
let _token = "";

const END_USER_URL = process.env.CF_URL + "EndUserJS.cfc?";
const DOWNLOAD_FILE_URL = process.env.CF_URL + "show_report_json.cfm?";

/*  IE tends to cache content, so subsequent calls may return the same data. 
    This is intended as a cache breaker */
function getRandomStr() {
  return new Date().getTime().toString();
}

export function setToken(token) {
  token !== "" ? (_token = token + "&") : (_token = "");
}

export function getManagementReports(reportType = "managementReports") {
  switch (reportType) {
    case managementFiles.CONTRACT:
    case managementFiles.INVOICE:
    case managementFiles.MANAGEMENT:
    case managementFiles.MANAGEMENT_ANNOTATED:
    case managementFiles.MANAGEMENT_ANNOTATED_ARCHIVED:
    case managementFiles.MANAGEMENT_ARCHIVED:
    case managementFiles.QBR:
    case managementFiles.QBR_ARCHIVED:
    case managementFiles.QBR_SUPPLEMENTAL:
    case managementFiles.QBR_SUPPLEMENTAL_ARCHIVED:
    case managementFiles.QBR_CONTRACT:
    case managementFiles.QBR_SURVEY:
    case managementFiles.NEWS:
    case managementFiles.NEWS_ARCHIVED:
    case managementFiles.SOP:
    case managementFiles.SUPPLEMENTAL:
    case managementFiles.SUPPLEMENTAL_ARCHIVED:
    case managementFiles.SURVEY: {
      let urlParams = JSON.stringify({
        type: reportType,
      });
      return fetch(
        END_USER_URL +
          _token +
          "method=getManagementReportsJS&contentVO=" +
          btoa(unescape(encodeURIComponent(urlParams))) +
          "&randomStr=" +
          getRandomStr()
      )
        .then(handleResponse)
        .catch(handleError);
    }
    default:
  }
}


export function getTeamReports(reportType = "employeesClockedIn") {
  switch (reportType) {
    case teamTypes.EMPLOYEES_CLOCKED_IN: {
      let urlParams = JSON.stringify({
        type: reportType,
      });
      return fetch(
        END_USER_URL +
          _token +
          "method=getTeamReportsJS&contentVO=" +
          btoa(unescape(encodeURIComponent(urlParams))) +
          "&randomStr=" +
          getRandomStr()
      )
        .then(handleResponse)
        .catch(handleError);
    }
    default:
    // console.log(reportType + " not found");
  }
}

export function getEmployeeProfile(args) {
  let urlParams = JSON.stringify({
    empId: args,
  });

  return fetch(
    END_USER_URL +
      _token +
      "method=getEmployeeProfileJS&contentVO=" +
      btoa(unescape(encodeURIComponent(urlParams))) +
      "&randomStr=" +
      getRandomStr()
  )
    .then(handleResponse)
    .catch(handleError);
}

export function getCopyRecords(copyType = "copyJobsUser") {
  switch (copyType) {
    case copyTypes.COPY_JOBS_ALL:
    case copyTypes.COPY_JOBS_USER: {
      let urlParams = JSON.stringify({
        type: copyType,
      });
      return fetch(
        END_USER_URL +
          _token +
          "method=getCopyJobsJS&copyType=" +
          btoa(unescape(encodeURIComponent(urlParams))) +
          "&randomStr=" +
          getRandomStr()
      )
        .then(handleResponse)
        .catch(handleError);
    }
    default:
    // console.log(copyType + " not found");
  }
}

export function getSiteManagement() {
  return fetch(END_USER_URL + _token + "method=getSiteEmployeesJS&listType=local&randomStr=" + getRandomStr())
    .then(handleResponse)
    .catch(handleError);
}
export function getSiteSeniorManagement() {
  return fetch(END_USER_URL + _token + "method=getSiteEmployeesJS&listType=senior&randomStr=" + getRandomStr())
    .then(handleResponse)
    .catch(handleError);
}

export function getShowReportFile(args) {
  // gets a file using show_report_json.cfm and the encrypted file name and path
  window.open(
    DOWNLOAD_FILE_URL +
      _token +
      "args=" +
      btoa(unescape(encodeURIComponent(JSON.stringify(args)))) +
      "&randomStr=" +
      getRandomStr(),
    "_blank"
  );
}

export function openLocation(args) {
  // gets a file using show_report_json.cfm and the encrypted file name and path
  if (args.type === "path") {
    window.open(args.value + "?" + _token + "&randomStr=" + getRandomStr(), "_blank");
  } else if (args.type === "location") {
    if (args.location.search("\\?") > 0) {
      if (_token !== "") {
        window.open(args.location + "?" + _token + "&randomStr=" + getRandomStr(), "_blank");
      } else {
        window.open(args.location + "?" + "&randomStr=" + getRandomStr(), "_blank");
      }
    } else {
      if (_token !== "") {
        window.open(args.location + "?" + _token + "&randomStr=" + getRandomStr(), "_blank");
      } else {
        window.open(args.location + "?" + "&randomStr=" + getRandomStr(), "_blank");
      }
    }
  }
}
