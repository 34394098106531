import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function workorderInitialCookieValuesReducer(state = initialState.workorderInitialCookieValues, action) {
  switch (action.type) {
    case types.SET_WORKORDER_INITIAL_COOKIE_VALUES:
      return action.result;
    default:
      return state;
  }
}
