import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function copyContentReducer(state = initialState.clientIsPhone, action) {
  switch (action.type) {
    case types.SET_CLIENT_IS_PHONE:
      return action.clientIsPhone;
    default:
      return state;
  }
}
