import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function teamContentReducer(state = initialState.teamContent, action) {
  switch (action.type) {
    case types.GET_TEAM_SUCCESS:
      return action.teamContent;
    case types.CLEAR_TEAM_CONTENT:
      return initialState.teamContent;
    default:
      return state;
  }
}
