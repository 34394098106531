import { handleResponse, handleError } from "./apiUtils";

/* API for Records */
let _token = "";

const END_USER_URL = process.env.CF_URL + "EndUserJS.cfc?";
const DOWNLOAD_FILE_URL = process.env.CF_URL + "show_report_json.cfm?";

/*  IE tends to cache content, so subsequent calls may return the same data. 
    This is intended as a cache breaker */
function getRandomStr() {
  return new Date().getTime().toString();
}

export function setToken(token) {
  _token = token + "&";
}

export function createSqlReport(params) {
  let urlParams = JSON.stringify(params);
  return fetch(
    END_USER_URL +
      _token +
      "method=printReportJS&contentVO=" +
      btoa(unescape(encodeURIComponent(urlParams))) +
      "&randomStr=" +
      getRandomStr()
  )
    .then(handleResponse)
    .catch(handleError);
}

export function getRecordsContent() {
  return fetch(END_USER_URL + _token + "method=getRecordsManagementVolumeJS&randomStr=" + getRandomStr())
    .then(handleResponse)
    .catch(handleError);
}
export function openLocation(args) {
  // submit for report
  let _args = { type: "path", value: btoa(unescape(encodeURIComponent(args.location))) };
  window.open(
    DOWNLOAD_FILE_URL +
      _token +
      "args=" +
      btoa(unescape(encodeURIComponent(JSON.stringify(_args)))) +
      "&randomStr=" +
      getRandomStr(),
    "_blank"
  );
}
