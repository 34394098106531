import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function copyContentReducer(state = initialState.copyContent, action) {
  switch (action.type) {
    case types.GET_COPY_CONTENT_SUCCESS:
      return action.copyContent;
    case types.CLEAR_COPY_CONTENT:
      return initialState.copyContent;
    default:
      return state;
  }
}
