import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function changePasswordReducer(state = initialState.changePwResult, action) {
  switch (action.type) {
    case types.CHANGE_PASSWORD_SUCCESS:
      return action.changePwResult && action.changePwResult ? action.changePwResult : "";
    case types.RESET_CHANGE_PASSWORD_SUCCESS:
      return initialState.changePwResult;
    default:
      return state;
  }
}
