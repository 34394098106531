import * as types from "./actionTypes";
import * as contentApi from "../../api/managementTeamCopyApi";
import * as _ from "lodash";
import "../../utils/lodashMixins.js";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import { managementLabels } from "../../constants/managementTypes";
import { teamLabels } from "../../constants/teamTypes";
import { copyLabels } from "../../constants/copyTypes";

function getIsAuthenticatedSuccess(authenticate) {
  return { type: types.GET_IS_AUTHENTICATED_SUCCESS, authenticate };
}

function getManagementReportsSuccess(managementContent) {
  return { type: types.GET_REPORTS_SUCCESS, managementContent };
}

function clearManagementContent() {
  return { type: types.CLEAR_MANAGEMENT_CONTENT };
}

function getTeamReportsSuccess(teamContent) {
  return { type: types.GET_TEAM_SUCCESS, teamContent };
}

function getEmployeeProfileSuccess(employeeProfile) {
  return { type: types.GET_EMPLOYEE_PROFILE_SUCCESS, employeeProfile: _.sortKeysBy(employeeProfile) };
}

function clearTeamContent() {
  return { type: types.CLEAR_TEAM_CONTENT };
}

function getSiteManagementSuccess(siteManagement) {
  return { type: types.GET_SITE_MANAGEMENT_SUCCESS, siteManagement };
}

function getSiteSeniorManagementSuccess(seniorManagement) {
  return { type: types.GET_SITE_SENIOR_MANAGEMENT_SUCCESS, seniorManagement };
}

function getCopyContentSuccess(copyContent) {
  return { type: types.GET_COPY_CONTENT_SUCCESS, copyContent };
}
function clearCopyContent() {
  return { type: types.CLEAR_COPY_CONTENT };
}
function clearEmployeeProfileContent() {
  return { type: types.CLEAR_EMPLOYEE_PROFILE };
}

/*  Get menu specific display data for the Management tab. 
    These calls contain a check of isAuthenticated() without additional data (menus, tabs, etc.)
    The result is returned (Authenticate object), so it works for us as a check on session timeout
    and is handled automatically */
function managementReports(dispatch, params) {
  let tmp = {};
  dispatch(clearManagementContent());
  dispatch(beginApiCall());
  // we will be dispatching two return calls, one for Authenticate and one for menu results, so need to increment twice
  dispatch(beginApiCall());
  return contentApi
    .getManagementReports(managementLabels[params])
    .then((result) => {
      tmp[managementLabels[params]] = result[`${managementLabels[params]}`];
      dispatch(getIsAuthenticatedSuccess(result.authenticate)); // this decrements open API calls
      dispatch(getManagementReportsSuccess(tmp));
    })
    .catch((error) => {
      dispatch(apiCallError(error));
      throw error;
    });
}

/*  get menu specific content for the Team tab*/
function teamReports(dispatch, params) {
  let tmp = {};
  dispatch(clearTeamContent());
  dispatch(beginApiCall());
  return contentApi
    .getTeamReports(teamLabels[params])
    .then((result) => {
      tmp[teamLabels[params]] = result[`${teamLabels[params]}`];
      dispatch(getTeamReportsSuccess(tmp));
    })
    .catch((error) => {
      dispatch(apiCallError(error));
      throw error;
    });
}

/*  get menu specific content for the Copy tab*/
function copyReports(dispatch, params) {
  dispatch(clearCopyContent());
  dispatch(beginApiCall());
  return contentApi
    .getCopyRecords(copyLabels[params])
    .then((result) => {
      dispatch(getCopyContentSuccess(result.copyContent));
    })
    .catch((error) => {
      dispatch(apiCallError(error));
      throw error;
    });
}

/*  generic call used by three tabs to get content specific for that tab */
export function getContent(params) {
  return function (dispatch) {
    if (Object.prototype.hasOwnProperty.call(managementLabels, params)) {
      return managementReports(dispatch, params);
    } else if (Object.prototype.hasOwnProperty.call(teamLabels, params)) {
      return teamReports(dispatch, params);
    } else if (Object.prototype.hasOwnProperty.call(copyLabels, params)) {
      return copyReports(dispatch, params);
    }
  };
}

/* called on initial load of Team tab, to get team members for current site */
export function getSiteManagement() {
  return function (dispatch) {
    // dispatch(clearSiteManagement());
    dispatch(beginApiCall());
    return contentApi
      .getSiteManagement()
      .then((result) => {
        dispatch(getSiteManagementSuccess(result));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}
/* called on initial load of Team tab, to get team members for current site */
export function getSiteSeniorManagement() {
  return function (dispatch) {
    // dispatch(clearSiteSeniorManagement());
    dispatch(beginApiCall());
    return contentApi
      .getSiteSeniorManagement()
      .then((result) => {
        dispatch(getSiteSeniorManagementSuccess(result));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function getEmployeeProfile(params) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return contentApi
      .getEmployeeProfile(params)
      .then((result) => {
        dispatch(getEmployeeProfileSuccess(result));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

/*  generic call used by any tab to open a file from the server */
export function getDownloadFile(params) {
  return function () {
    return contentApi.getShowReportFile(params);
  };
}

export function getLocation(params) {
  return function () {
    return contentApi.openLocation(params);
  };
}

export function clearProfileContent() {
  return function (dispatch) {
    dispatch(clearEmployeeProfileContent());
  };
}
