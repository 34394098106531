import * as types from "../actions/actionTypes";
import initialState from "./initialState";
import _ from "lodash";

export default function workOrderReducer(state = initialState.workOrder, action) {
  switch (action.type) {
    case types.CLEAR_WORKORDER:
      return initialState.workOrder;
    case types.GET_WORKORDERS_SUCCESS:
      return action.result;
    case types.SET_WORKORDER_ATTACHMENT_STATUS:
      /* change the current work order record to reflect having an attachment, so the workorders table record will
      have that value without a server refresh. This facilitates reopening the record from the table, and firing a "get attachments" function
      to display the attachments */
      return {
        ...state,
        workOrders: _workOrdersWithAttachmentIdSet(state.workOrders, action.params.workOrder),
        // locations: action.result.locations,
      };
    case types.ADD_UPDATE_WORKORDER_SUCCESS:
      /* replace the workorder in the workorders list with the workorder returned from the server */
      return {
        ...state,
        ...action.result,
      };
    default:
      return state;
  }
}

const _workOrdersWithAttachmentIdSet = (currentWorkorders, workOrder) => {
  let workOrders = _.cloneDeep(currentWorkorders);
  if (!isNaN(workOrder.workorder_id)) {
    let wo = _.find(workOrders, { workorder_id: workOrder.workorder_id });
    wo.has_picture = 1;
  }
  return workOrders;
};
