import React, { useState, useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import "isomorphic-fetch";
import { ToastContainer } from "react-toastify";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Popup } from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import LoadingIndicator from "./common/LoadingIndicator";
import { getSiteManagement } from "../redux/actions/managementTeamCopyActions";

import Header from "./common/Header"; // eslint-disable-line import/no-named-as-default
import HeaderMobile from "./common/HeaderMobile"; // eslint-disable-line import/no-named-as-default

import "react-toastify/dist/ReactToastify.css";
import "../assets/styles/Popup.css";
import "semantic-ui-css/semantic.min.css";

/* import LoginPage from "./login/LoginPage";
import PageNotFound from "./PageNotFound";
import HomePage from "./tabs/Home";
import Management from "./tabs/Management";
import Team from "./tabs/Team";
import Copy from "./tabs/Copy";
import Mailroom from "./tabs/Mailroom";
import Equipment from "./tabs/Equipment";
import Hospitality from "./tabs/Hospitality";
import Court from "./tabs/Court";
import Work from "./tabs/Work";
import Records from "./tabs/Records"; */

import { library } from "@fortawesome/fontawesome-svg-core";
import { faHome } from "@fortawesome/free-solid-svg-icons/faHome";
import { faPowerOff } from "@fortawesome/free-solid-svg-icons/faPowerOff";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import { faFileExcel } from "@fortawesome/free-regular-svg-icons/faFileExcel";
import { faFileWord } from "@fortawesome/free-regular-svg-icons/faFileWord";
import { faFilePdf } from "@fortawesome/free-regular-svg-icons/faFilePdf";
import { faFilePowerpoint } from "@fortawesome/free-regular-svg-icons/faFilePowerpoint";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faFile } from "@fortawesome/free-regular-svg-icons/faFile";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons/faTrashAlt";
library.add(
  faHome,
  faPowerOff,
  faChevronRight,
  faChevronLeft,
  faFileExcel,
  faFileWord,
  faFilePdf,
  faFilePowerpoint,
  faEnvelope,
  faFile,
  faTrashAlt
);

const LoginPage = React.lazy(() => import("./login/LoginPage"));
const PageNotFound = React.lazy(() => import("./PageNotFound"));
const HomePage = React.lazy(() => import("./tabs/Home"));
const Management = React.lazy(() => import("./tabs/Management"));
const Team = React.lazy(() => import("./tabs/Team"));
const Copy = React.lazy(() => import("./tabs/Copy"));
const Mailroom = React.lazy(() => import("./tabs/Mailroom"));
const Equipment = React.lazy(() => import("./tabs/Equipment"));
const Hospitality = React.lazy(() => import("./tabs/Hospitality"));
const Court = React.lazy(() => import("./tabs/Court"));
const Work = React.lazy(() => import("./tabs/Work"));
const Records = React.lazy(() => import("./tabs/Records"));
const EDiscovery = React.lazy(() => import("./tabs/ediscovery/EDiscovery"));
const Imaging = React.lazy(() => import("./tabs/Imaging"));

const usePageViews = (getSiteManagement, siteManagement) => {
  let location = useLocation();

  useEffect(() => {
    switch (location.pathname) {
      case "/home":
        break;
      case "/copy":
        break;
      case "/equipment":
        break;
      case "/team":
        siteManagement.length > 0 &&
          siteManagement[0].first_name === "" &&
          siteManagement[0].site_id === 0 &&
          getSiteManagement();
        break;
      case "/mailroom":
        break;
      case "/management":
        break;
      case "/workorder":
        break;
      default:
      //these are not the droids you seek
    }
  }, [location, siteManagement, getSiteManagement]);
};
const App = ({ getSiteManagement, siteManagement }) => {
  const [hasContent, setHasContent] = useState(false);
  usePageViews(getSiteManagement, siteManagement);
  return (
    <>
      <div className="d-none d-lg-block">
        <Header setHasContent={setHasContent} />
      </div>
      <div className="d-lg-none">
        <HeaderMobile setHasContent={setHasContent} />
      </div>
      <React.Suspense fallback={<LoadingIndicator />}>
        <Switch>
          <Route exact path="/" render={(props) => <HomePage {...props} menuFilter={"home"} />} />
          <Route path="/home" render={(props) => <HomePage {...props} menuFilter={"home"} />} />
          <Route
            path="/management"
            render={(props) => {
              return (
                <Management
                  {...props}
                  menuFilter={"management"}
                  hasContent={hasContent}
                  setHasContent={setHasContent}
                />
              );
            }}
          />
          <Route path="/login" component={LoginPage} />
          <Route
            path="/team"
            render={(props) => {
              return <Team {...props} menuFilter={"team"} hasContent={hasContent} setHasContent={setHasContent} />;
            }}
          />
          <Route
            path="/copy"
            render={(props) => {
              return <Copy {...props} menuFilter={"copy"} hasContent={hasContent} setHasContent={setHasContent} />;
            }}
          />
          <Route
            path="/mailroom"
            render={(props) => {
              return (
                <Mailroom {...props} menuFilter={"mailroom"} hasContent={hasContent} setHasContent={setHasContent} />
              );
            }}
          />
          <Route
            path="/equipment"
            render={(props) => {
              return (
                <Equipment {...props} menuFilter={"equipment"} hasContent={hasContent} setHasContent={setHasContent} />
              );
            }}
          />
          <Route
            path="/hospitality"
            render={(props) => (
              <Hospitality
                {...props}
                menuFilter={"hospitality"}
                hasContent={hasContent}
                setHasContent={setHasContent}
              />
            )}
          />
          <Route
            path="/e-discovery"
            render={(props) => (
              <EDiscovery {...props} menuFilter={"e-discovery"} hasContent={hasContent} setHasContent={setHasContent} />
            )}
          />
          <Route
            path="/conference"
            render={(props) => (
              <Hospitality {...props} menuFilter={"conference"} hasContent={hasContent} setHasContent={setHasContent} />
            )}
          />
          <Route path="/court" render={(props) => <Court {...props} menuFilter={"court"} />} />
          <Route
            path="/work"
            render={(props) => {
              return <Work {...props} menuFilter={"work"} hasContent={hasContent} setHasContent={setHasContent} />;
            }}
          />
          <Route
            path="/records"
            render={(props) => (
              <Records {...props} menuFilter={"records"} hasContent={hasContent} setHasContent={setHasContent} />
            )}
          />
          <Route
            path="/imaging"
            render={(props) => (
              <Imaging {...props} menuFilter={"imaging"} hasContent={hasContent} setHasContent={setHasContent} />
            )}
          />
          <Route component={PageNotFound} />
        </Switch>
      </React.Suspense>
      <ToastContainer autoClose={3000} hideProgressBar />
      {/* instantiate the Popup component into the DOM */}
      <Popup
        className="mm-popup"
        btnClass="mm-popup__btn"
        closeBtn={true}
        closeHtml={null}
        defaultOk="Ok"
        defaultCancel="Cancel"
        wildClasses={false}
        escToClose={true}
      />
    </>
  );
};
App.propTypes = {
  getSiteManagement: PropTypes.func.isRequired,
  siteManagement: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  siteManagement: state.siteManagement,
});

const mapDispatchToProps = {
  getSiteManagement,
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
