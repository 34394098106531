import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function mailroomContentDetailReducer(state = initialState.mailroomContentDetail, action) {
  switch (action.type) {
    case types.GET_MAILROOM_DETAIL_SUCCESS:
      return action.mailroomContentDetail;
    case types.CLEAR_MAILROOM_CONTENT_DETAIL:
      return {};
    default:
      return state;
  }
}
