const initialState = {
  apiCallsInProgress: 0,
  authenticate: {
    allowArchive: 0,
    allowDelete: 0,
    eula_type: "",
    isRefresh: true,
    mailroomEmail: "",
    mailroomPhone: "",
    my_mpf_level: 0,
    password_reset_descr: "",
    password_reset_status: 0,
    result_descr: "", // login result
    result_val: 0,
    sec_user_first_name: "",
    sec_user_id: 0,
    sec_user_last_name: "",
    sec_user_name: "",
    sec_user_phone: "",
    sec_user_valid: false,
    selected_site_id: 0,
    selected_site_name: "",
    selected_sec_organization_id: 0,
    site_id: 0,
    site_name: "",
    pw_change_prompt: 0,
    pwReqMessage: "",
  },
  copyContent: [
    {
      timedue: "",
      orderid: 0,
      customer: "",
      department: "",
      print_status: "",
      finish_status: "",
      firstname: "",
      lastname: "",
      completeddate: "",
      deptcode: "",
      actualdatetime: "",
      confirmdatetime: "",
      current_date_time: "",
      due_status: "",
      rownum: 0,
      totalrowcount: 0,
      order_details: [],
      products_list: "",
      impressions: 0,
      timedue_str: "",
    },
  ],
  createLogin: {
    check_email_domain_descr: [], // when populated, contains array of sites common to the email domain
    check_email_domain_status: 0, //indicates success or failure
    create_login_descr: "", // result message from creating login
    create_login_status: 0, // login creation success or failure
  },
  employeeProfile: {
    // employeePicture: "",
    // employeeSopCompliance: {
    //   sitesp_percent: 0,
    //   sop_percent: 0,
    // },
    // istWorkingHistory: [
    //   {
    //     dept_name: "",
    //     type: "",
    //     pos_title: "",
    //     effect_date: null,
    //   },
    // ],
    // nonIstWorkingHistory: [
    //   {
    //     position: "",
    //     employer: 0,
    //     start_date: 0,
    //     end_date: "",
    //   },
    // ],
    // profile: {
    //   disc_code: "",
    //   size_desc: "",
    //   acc_date: "",
    //   dept_name: "",
    //   type_id: "",
    //   hire_date: "",
    //   grade: 0,
    //   employee_name: "",
    //   position_start_date: "",
    //   pos_title: "",
    // },
    // specialNotes: {
    //   school: "",
    //   interests: "",
    //   awards: "",
    // },
    // trainingSkills: [
    //     col2_service_name: "",
    //     col4_pct: 0,
    //     col3_pct: 0,
    //     col1_service_name: "",
    //     col1_pct: 0,
    //     col2_pct: 0,
    //     col4_service_name: "",
    //     col3_service_name: "",
    // ],
  },
  equipment: {},
  equipmentMachineServiceHistory: {},
  equipmentServiceCallDetail: {},
  eula: "initial",
  features: [],
  clientIsPhone: false,
  loading: false,
  loggingOut: false,
  mailroomContent: {
    // certifiedMail: [],
    // courtFilings: [],
    // mailSortVolumes: [],
    // meterReads: [],
    // packages: []
  },
  mailroomContentDetail: {},
  managementContent: {
    contractFiles: [
      {
        label: "no documents found",
        url: "",
        location: "",
        type: "contracts",
        allowDelete: 0,
        allowArchive: 0,
        thumbnail_str: "",
      },
    ],
    invoiceFiles: [
      {
        invoice_date: "",
        url: "",
        aim_prebill_merge_file_name: "",
        aim_prebill_merge_file_path: "",
        aim_overage_merge_file_name: "",
        aim_overage_merge_file_path: "",
        thumbnail_str: "",
      },
    ],
    managementReports: [
      {
        label: "no documents found",
        url: "",
        location: "",
        type: "managementReports",
        allowDelete: 0,
        allowArchive: 0,
        thumbnail_str: "",
      },
    ],
    managementReportsAnnotated: [
      {
        label: "no documents found",
        url: "",
        location: "",
        type: "managementReportsAnnotated",
        allowDelete: 0,
        allowArchive: 0,
        thumbnail_str: "",
      },
    ],
    managementReportsAnnotatedArchived: [
      {
        label: "no documents found",
        url: "",
        location: "",
        type: "managementReportsAnnotatedArchived",
        allowDelete: 0,
        allowArchive: 0,
        thumbnail_str: "",
      },
    ],
    managementReportsArchived: [
      {
        label: "no documents found",
        url: "",
        location: "",
        type: "managementReportsArchived",
        allowDelete: 0,
        allowArchive: 0,
        thumbnail_str: "",
      },
    ],
    newsFiles: [
      {
        label: "no documents found",
        url: "",
        location: "",
        type: "news",
        allowDelete: 0,
        allowArchive: 0,
        thumbnail_str: "",
      },
    ],
    newsFilesArchived: [
      {
        label: "no documents found",
        url: "",
        location: "",
        type: "newsArchived",
        allowDelete: 0,
        allowArchive: 0,
        thumbnail_str: "",
      },
    ],
    sops: [
      {
        label: "no documents found",
        url: "",
        location: "",
        type: "sop",
        allowDelete: 0,
        allowArchive: 0,
      },
    ],
    supplementalReports: [
      {
        label: "no documents found",
        url: "",
        location: "",
        type: "supplementalReports",
        allowDelete: 0,
        allowArchive: 0,
        thumbnail_str: "",
      },
    ],
    supplementalReportsArchived: [
      {
        label: "no documents found",
        url: "",
        location: "",
        type: "supplementalReportsArchived",
        allowDelete: 0,
        allowArchive: 0,
        thumbnail_str: "",
      },
    ],
    surveyFiles: [
      {
        label: "no documents found",
        url: "",
        location: "",
        type: "surveys",
        allowDelete: 0,
        allowArchive: 0,
        thumbnail_str: "",
      },
    ],
  },
  menus: [
    {
      bg_image: "",
      navName: "",
      tabContent: [
        {
          children: [],
          label: "",
          location: "",
          type: "",
          url: "",
          window: "",
        },
      ],
      tabName: "",
      topicContent: "",
    },
  ],
  recordsContent: {
    // recordsManagementVolume: []
  },
  scheduledReports: [],
  screenToggles: {
    createLogin: false, //toggles CreateAccount page visibility
    resetPassword: false, //toggles PasswordReset page visibility
    showEULA: false, // toggles ShowEula page visibility
  },
  selectedSiteId: 0,
  seniorManagement: [
    {
      emp_id: 0,
      first_name: "",
      last_name: "",
      network_id: "",
      phone: "",
      email: "",
      title: "",
      grade: 0,
      picture_str: "",
      site_name: "",
      site_id: 0,
    },
  ],
  siteManagement: [
    {
      emp_id: 0,
      first_name: "",
      last_name: "",
      network_id: "",
      phone: "",
      email: "",
      title: "",
      grade: 0,
      picture_str: "",
      site_name: "",
      site_id: 0,
    },
  ],
  sitesList: [{ site_id: 0, site_name: "", sec_organization_id: 0 }],
  teamContent: {
    employeesClockedIn: [
      {
        emp_fmname: "",
        emp_lname: "",
      },
    ],
  },
  workOrder: {
    /*
    assignedToFilterList: [],
    creatorFilterList: [],
    statusTypes: [],
    locations: [],
    services: [],
    workorderEmployees: [],
    workOrders: [],
    workOrdersCount: 0,
    */
  },
  workorderAttachments: {
    /* 
    site_id: 0,
    attachments:[] 
    */
  },
  workorderInitialCookieValues: { foo: "baar" },
  passwordReset: {
    password_reset_descr: "",
    password_reset_status: false,
  },
  showEULA: false,
  showWindowPortal: false,
  siteDashboardList: {},
  jotForm: {
    status: "",
    statusMessage: "",
  },
  changePwResult: {
    logoninfo_pw_result: -1,
    logoninfo_pw_msg: "",
  },
};

export default initialState;
