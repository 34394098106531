import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function equipmentMachineServiceHistoryReducer(
  state = initialState.equipmentMachineServiceHistory,
  action
) {
  switch (action.type) {
    case types.GET_EQUIPMENT_MACHINE_SERVICE_HISTORY_SUCCESS:
      return action.result;
    case types.CLEAR_EQUIPMENT_MACHINE_SERVICE_HISTORY:
      return {};
    default:
      return state;
  }
}
