import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function createLoginReducer(
  state = initialState.createLogin,
  action
) {
  switch (action.type) {
    case types.CREATE_LOGIN_SUCCESS:
      return action.createLogin;
    default:
      return state;
  }
}
