/* API for Hospitality */
const DOWNLOAD_FILE_URL = process.env.CF_URL + "show_report_json.cfm?";
let _token = "";

export function setToken(token) {
  _token = token + "&";
}

/*  IE tends to cache content, so subsequent calls may return the same data. 
    This is intended as a cache breaker */
function getRandomStr() {
  return new Date().getTime().toString();
}

export function openLocation(args) {
  // gets a file using show_report_json.cfm and the encrypted file name and path
  if (args.type === "location") {
    window.open(args.location + "?" + _token + "&randomStr=" + getRandomStr(), "_blank");
  } else if (args.type === "pdf") {
    // submit for report
    let _args = { type: "path", value: btoa(unescape(encodeURIComponent(args.location))) };
    window.open(
      DOWNLOAD_FILE_URL +
        _token +
        "randomStr=" +
        getRandomStr() +
        "&args=" +
        btoa(unescape(encodeURIComponent(JSON.stringify(_args)))),
      "_blank"
    );
  }
}
