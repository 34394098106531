import React from "react";
import { connect } from "react-redux";
import { ScaleLoader as Spinner } from "react-spinners";
import PropTypes from "prop-types";

const LoadingIndicator = (props) => {
  return (
    <div>
      <Spinner size={60} color={"#12672f"} loading={props.loading} />
    </div>
  );
};

LoadingIndicator.propTypes = {
  loading: PropTypes.bool.isRequired,
};
function mapStateToProps(state) {
  const loading = state.apiCallsInProgress > 0;
  return {
    loading,
  };
}
export default connect(mapStateToProps)(LoadingIndicator);
