import { handleResponse, handleError } from "./apiUtils";

let _token = "";

const END_USER_URL = process.env.CF_URL + "EndUserJS.cfc?";

/*  IE tends to cache content, so subsequent calls may return the same data. 
    This is intended as a cache breaker */
function getRandomStr() {
  return new Date().getTime().toString();
}

export function setToken(token) {
  _token = token + "&";
}

export function getIsAuthenticated() {
  return fetch(END_USER_URL + _token + "method=isAuthenticatedJS&randomStr=" + getRandomStr())
    .then(handleResponse)
    .catch(handleError);
}

// simply check whether authenticated without the overhead of getting all menus and content
export function checkIsAuthenticated() {
  return fetch(END_USER_URL + _token + "method=isAuthenticatedBriefJS&randomStr=" + getRandomStr())
    .then(handleResponse)
    .catch(handleError);
}

// NOTE: We are encoding using btoa(arg) to prevent special characters from breaking functionality.
//        Also, wrapping argument in unescape(encodeURIComponent()) as in btoa(unescape(encodeURIComponent(arg)))
// Encoded data is expected, and decoding is performed on back end target
export function getLogin(args) {
  let urlParams = JSON.stringify({
    sec_user_name: args.sec_user_name,
    sec_user_password: args.sec_user_password,
  });
  return fetch(
    END_USER_URL +
      _token +
      "method=loginJS&contentVO=" +
      btoa(unescape(encodeURIComponent(urlParams))) +
      "&randomStr=" +
      getRandomStr()
  )
    .then(handleResponse)
    .catch(handleError);
}

export function getLogout() {
  return fetch(END_USER_URL + _token + "method=doLogoutJS&randomStr=" + getRandomStr())
    .then(handleResponse)
    .catch(handleError);
}

export function getSiteChange(args) {
  let siteChangeParams = JSON.stringify({
    site_id: args.site_id,
    site_name: args.site_name,
    sec_organization_id: args.sec_organization_id,
    sec_user_name: args.sec_user_name,
  });
  return fetch(
    END_USER_URL +
      _token +
      "method=changeSiteJS&contentVO=" +
      btoa(unescape(encodeURIComponent(siteChangeParams))) +
      "&randomStr=" +
      getRandomStr()
  )
    .then(handleResponse)
    .catch(handleError);
}

export function getAlternateLogin(args) {
  let formData = new FormData();
  formData.append("sec_user_name", args.sec_user_name);

  return fetch(END_USER_URL + _token + "method=getAlternateLogin&randomStr=" + getRandomStr(), {
    method: "POST",
    body: formData,
  })
    .then(handleResponse)
    .catch(handleError);
}

export function resetPassword(args = "") {
  let formData = new FormData();
  formData.append("sec_user_email", args.sec_user_name);

  return fetch(END_USER_URL + _token + "method=resetPasswordJS&randomStr=" + getRandomStr(), {
    method: "POST",
    body: formData,
  })
    .then(handleResponse)
    .catch(handleError);
}

export function createLogin(args, siteId) {
  let formData = new FormData();
  formData.append("sec_user_first_name", args.sec_user_first_name);
  formData.append("sec_user_last_name", args.sec_user_last_name);
  formData.append("sec_user_email", args.sec_user_name);
  formData.append("sec_user_phone_1", args.sec_user_phone);
  if (siteId > 0) {
    formData.append("siteId", siteId);
  }
  return fetch(END_USER_URL + _token + "method=createLoginJS&randomStr=" + getRandomStr(), {
    method: "POST",
    body: formData,
  })
    .then(handleResponse)
    .catch(handleError);
}

export function checkEmailDomain(args) {
  let formData = new FormData();
  formData.append("sec_user_email", args.sec_user_email);

  return fetch(END_USER_URL + _token + "method=checkEmailDomainJS&randomStr=" + getRandomStr(), {
    method: "POST",
    body: formData,
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getEula() {
  return fetch(END_USER_URL + _token + "method=getEulaJS&randomStr=" + getRandomStr())
    .then(handleResponse)
    .catch(handleError);
}
