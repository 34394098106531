import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function mailroomContentReducer(state = initialState.mailroomContent, action) {
  switch (action.type) {
    case types.GET_MAILROOM_CONTENT_SUCCESS:
      return action.result;
    case types.CLEAR_MAILROOM_CONTENT:
      return initialState.mailroomContent;
    default:
      return state;
  }
}
