import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function screenTogglesReducer(state = initialState.screenToggles, action) {
  switch (action.type) {
    case types.TOGGLE_CREATE_ACCOUNT:
    case types.TOGGLE_EULA:
    case types.TOGGLE_PASSWORD_RESET:
      return action.params;
    default:
      return state;
  }
}
