import { toast } from "react-toastify";
import * as types from "./actionTypes";
import * as authenticationApi from "../../api/authenticationApi";
import * as mailroomApi from "../../api/mailroomApi";
import * as managementTeamCopyApi from "../../api/managementTeamCopyApi.js";
import * as serviceCallsApi from "../../api/serviceCallsApi";
import * as hospitalityApi from "../../api/hospitalityApi";
import * as recordsApi from "../../api/recordsApi";
import * as workOrderApi from "../../api/workOrderApi";
import * as passwordChangeApi from "../../api/passwordChangeApi";

import * as _ from "lodash";
import "../../utils/lodashMixins.js";

import { beginApiCall, apiCallError } from "./apiStatusActions";
import { clearContent } from "./clearContentActions";

function getIsAuthenticatedSuccess(authenticate) {
  let objAuthenticate = authenticate;
  objAuthenticate.isRefresh = false;
  return { type: types.GET_IS_AUTHENTICATED_SUCCESS, authenticate: _.sortKeysBy(objAuthenticate) };
}

function getIsAuthenticatedInitialSuccess(authenticate) {
  let objAuthenticate = authenticate;
  objAuthenticate.isRefresh = false;
  return { type: types.GET_IS_AUTHENTICATED_SUCCESS, authenticate: _.sortKeysBy(objAuthenticate) };
}

/*  IE tends to cache content, so subsequent calls may return the same data. 
    This is intended as a cache breaker */
function getRandomStr() {
  return new Date().getTime().toString();
}

function checkIsAuthenticatedThenOpenSuccess(authenticate, url, target) {
  if (authenticate.sec_user_valid) {
    window.open(url + "&randomStr=" + getRandomStr(), target);
  } else {
    toast.warn("Your session has expired.");
  }
  return { type: types.GET_IS_AUTHENTICATED_SUCCESS, authenticate: _.sortKeysBy(authenticate) };
}

function loginSuccess(authenticate) {
  let objAuthenticate = authenticate;
  objAuthenticate.isRefresh = false;
  return { type: types.GET_LOGIN_SUCCESS, authenticate: _.sortKeysBy(objAuthenticate) };
}

function logoutSuccess(authenticate) {
  let objAuthenticate = authenticate;
  objAuthenticate.isRefresh = false;
  return { type: types.LOGOUT_SUCCESS, authenticate: _.sortKeysBy(objAuthenticate) };
}

function getTabsAndMenus(menus) {
  return { type: types.GET_TABS_AND_MENUS, menus };
}

function getSitesList(sitesList) {
  return { type: types.GET_SITES_LIST, sitesList };
}

function resetPassword(passwordReset) {
  return {
    type: types.RESET_PASSWORD_SUCCESS,
    passwordReset,
  };
}

function clearCreateLogin() {
  let createLogin = {
    check_email_domain_descr: [], // when populated, contains array of sites common to the email domain
    check_email_domain_status: 0, //indicates success or failure
    create_login_descr: "", // result message from creating login
    create_login_status: 0,
  };
  return {
    type: types.CREATE_LOGIN_SUCCESS,
    createLogin,
  };
}

function clearPasswordReset() {
  let passwordReset = {
    password_reset_descr: "",
    password_reset_status: false,
  };
  return {
    type: types.RESET_PASSWORD_SUCCESS,
    passwordReset,
  };
}

function createLogin(result) {
  return { type: types.CREATE_LOGIN_SUCCESS, createLogin: result };
}

function getSiteSeniorManagementSuccess(seniorManagement) {
  return { type: types.GET_SITE_SENIOR_MANAGEMENT_SUCCESS, seniorManagement };
}

function _toggleCreateLogin(params) {
  return { type: types.TOGGLE_CREATE_ACCOUNT, params };
}

function _toggleResetPassword(params) {
  return { type: types.TOGGLE_PASSWORD_RESET, params };
}

function _toggleEULA(params) {
  return { type: types.TOGGLE_EULA, params };
}

function _getEula(result) {
  return { type: types.GET_EULA_SUCCESS, result };
}

function _clientIsPhone() {
  let clientIsPhone = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        a.substr(0, 4)
      )
    )
      clientIsPhone = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return { type: types.SET_CLIENT_IS_PHONE, clientIsPhone };
}

export function getIsAuthenticated() {
  return function (dispatch) {
    dispatch(beginApiCall());
    dispatch(beginApiCall());
    return authenticationApi
      .getIsAuthenticated()
      .then((result) => {
        authenticationApi.setToken(result.urltoken);
        mailroomApi.setToken(result.urltoken);
        managementTeamCopyApi.setToken(result.urltoken);
        serviceCallsApi.setToken(result.urltoken);
        hospitalityApi.setToken(result.urltoken);
        recordsApi.setToken(result.urltoken);
        workOrderApi.setToken(result.urltoken);
        passwordChangeApi.setToken(result.urltoken);
        dispatch(getTabsAndMenus(result.menus));
        dispatch(getIsAuthenticatedSuccess(result.authenticate));
        dispatch(getSitesList(result.sitesList));
        dispatch(getSiteSeniorManagementSuccess(result.seniorManagement));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}
export function getIsAuthenticatedInitial() {
  return function (dispatch) {
    dispatch(beginApiCall());
    dispatch(beginApiCall());
    return authenticationApi
      .getIsAuthenticated()
      .then((result) => {
        dispatch(_clientIsPhone());
        authenticationApi.setToken(result.urltoken);
        mailroomApi.setToken(result.urltoken);
        managementTeamCopyApi.setToken(result.urltoken);
        serviceCallsApi.setToken(result.urltoken);
        hospitalityApi.setToken(result.urltoken);
        recordsApi.setToken(result.urltoken);
        workOrderApi.setToken(result.urltoken);
        dispatch(getTabsAndMenus(result.menus));
        dispatch(getIsAuthenticatedInitialSuccess(result.authenticate));
        dispatch(getSitesList(result.sitesList));
        dispatch(getSiteSeniorManagementSuccess(result.seniorManagement));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

// simply check whether authenticated without the overhead of getting all menus and content
// This is used prior to clicking an external link
export function checkIsAuthenticatedThenOpen(url, target) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return authenticationApi
      .checkIsAuthenticated()
      .then((result) => {
        dispatch(checkIsAuthenticatedThenOpenSuccess(result.authenticate, url, target));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function getLogin(params) {
  return function (dispatch) {
    dispatch(clearContent());
    dispatch(beginApiCall());
    return authenticationApi
      .getLogin(params)
      .then((result) => {
        dispatch(_clientIsPhone());
        dispatch({ type: types.DECREMENT_API_CALLS_IN_PROGRESS_SUCCESS });
        if (result.authenticate.sec_user_valid === undefined || result.authenticate.sec_user_valid === 0) {
          dispatch(getIsAuthenticatedSuccess(result.authenticate));
        } else {
          /* make another pass to the server; initial login may not instantiate full user state (request scope, etc.) on first call, 
          so refresh state on return and fetch content again */
          dispatch(getIsAuthenticated());
        }
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function getLogout(authenticate) {
  return function (dispatch) {
    dispatch(clearContent());
    dispatch(beginApiCall());
    let returnUrl = authenticate.custom_return_url;
    return authenticationApi
      .getLogout()
      .then((result) => {
        dispatch(logoutSuccess(result.authenticate));
      })
      .then(() => {
        returnUrl !== "" && window.open(returnUrl, "_self", "noopener");
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function getSiteChange(params) {
  return function (dispatch) {
    dispatch(clearContent());
    dispatch(beginApiCall());
    dispatch(beginApiCall());
    return authenticationApi
      .getSiteChange(params)
      .then((result) => {
        // authenticationApi.setSessionId(result.urltoken);
        // mailroomApi.setSessionId(result.urltoken);
        // managementTeamCopyApi.setSessionId(result.urltoken);
        dispatch(getTabsAndMenus(result.menus));
        dispatch(loginSuccess(result.authenticate));
        dispatch(getSitesList(result.sitesList));
        dispatch(getSiteSeniorManagementSuccess(result.seniorManagement));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}
export function getResetPassword(params) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return authenticationApi
      .resetPassword(params)
      .then((result) => {
        dispatch(_toggleResetPassword({ resetPassword: false }));
        dispatch(resetPassword(result));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function getCreateLogin(params, siteId, button) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return authenticationApi
      .createLogin(params, siteId)
      .then((result) => {
        result.create_login_status === 1
          ? dispatch(_toggleCreateLogin({ createLogin: false }))
          : (button.disabled = false);
        dispatch(createLogin(result));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function getClearResetPassword() {
  return function (dispatch) {
    dispatch(clearPasswordReset());
  };
}

export function getClearCreateLogin() {
  return function (dispatch) {
    dispatch(clearCreateLogin());
  };
}

export function getEula() {
  return function (dispatch) {
    dispatch(beginApiCall());
    return authenticationApi.getEula().then((result) => {
      dispatch(_getEula(result))
        .then(dispatch(_toggleEULA({ showEULA: true })))
        .catch((error) => {
          dispatch(apiCallError(error));
          throw error;
        });
    });
  };
}
