import React from "react";
import PropTypes from "prop-types";
import "./siteSelectInput.css";

const SiteSelectInput = ({ name, label, onChange, defaultOption, value, error, options, disabled = false }) => {

  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <div className="field" style={{ paddingLeft: 10, paddingRight: 10 }}>
        {/* Note, value is set here rather than on the option - docs: https://facebook.github.io/react/docs/forms.html */}
        <select
          className="form-control form-control-override"
          name={name}
          value={value}
          onChange={onChange}
          disabled={disabled}
          style={{ marginBottom: "1em", paddingLeft: 10, paddingRight: 10 }}
        >
          <option value="">{defaultOption}</option>

          {options.map((option, index) => {
            option.propTypes = {
              site_id: PropTypes.string.isRequired,
              site_name: PropTypes.string.isRequired,
            };

            return (
              <OptionsItem value={option} index={index} key={index}/>
            );
          })}
        </select>
        {error && <div className="alert alert-danger">{error}</div>}
      </div>
    </div>
  );
};

const OptionsItem = (value, index) => {
  return (
    <option
      key={index}
      value={value.value.site_id}
      data-site_id={value.value.site_id}
      data-site_name={value.value.site_name}
      data-sec_organization_id={value.value.sec_organization_id}
    >
      {value?.value?.site_name} {value?.value?.site_id}
    </option>
  );
};

OptionsItem.propTypes = {
  value: PropTypes.object.isRequired,
  // site_id: PropTypes.string.isRequired,
  // sec_organization_id: PropTypes.string.isRequired,
  // text: PropTypes.string.isRequired,
  // site_name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};

SiteSelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultOption: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
};

export default SiteSelectInput;
