import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Navbar, Nav, Alert, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SiteSelectInput from "./SiteSelectInput";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import { getLogout, getSiteChange } from "../../redux/actions/authenticationActions";
import "../../assets/styles/index.css";
import LoadingIndicator from "../common/LoadingIndicator";
import "./Header.css";
import istLogo from "../../assets/images/ISTLogo.png";

const SiteListAlert = ({ handleSiteChange, sitesList, authenticate }) => {
  const errors = {};
  const [show, setShow] = useState(false);
  const onSiteChange = (event) => {
    handleSiteChange(event);
    setShow(false);
  };

  if (show) {
    return (
      <Alert variant="secondary" onClose={() => setShow(false)} dismissible>
        <SiteSelectInput
          name="selected_site_id"
          label=""
          value={authenticate.selected_site_id}
          defaultOption="Select Site"
          options={sitesList.map((site) => ({
            value: site.site_id,
            text: site.site_name,
            sec_organization_id: site.sec_organization_id,
            site_name: site.site_name,
            site_id: site.site_id,
          }))}
          onChange={onSiteChange}
          error={errors.error}
        />
      </Alert>
    );
  }
  return (
    <div style={{ justifySelf: "end", msGridColumnAlign: "end" }}>
      <Button onClick={() => setShow(true)} style={{ backgroundColor: "#343434" }}>
        ...
      </Button>
    </div>
  );
};

const homeIcon = <FontAwesomeIcon icon="home" size="lg" style={{ color: "green" }} />;
const logoutIcon = <FontAwesomeIcon icon="power-off" size="lg" color="green" />;

export const HeaderMobile = ({ getLogout, getSiteChange, ...props }) => {
  const [authenticate, setAuthenticate] = useState({
    ...props.authenticate,
  });
  const [loggingOut, setLoggingOut] = useState(false);

  useEffect(
    () => {
      setAuthenticate({ ...props.authenticate });
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.authenticate]
  );

  /* 
  Set to loggingOut to false whenever "Authenticate" changes.
  This is necessary because once this is rendered for the first time, and the value is set to log out,
  it is not re-rendered and the previous value "loggingOut" is still true. using useEffect and firing when Authenticate
  changes resets the value
  */
  useEffect(() => {
    setLoggingOut(false);
  }, [props.authenticate]);

  // call logout
  async function handleLogout() {
    setLoggingOut(true);
    getLogout().catch(() => {
      // console.log(error);
    });
  }

  // call site change
  function handleSiteChange(event) {
    const { name, value } = event.target;
    const args = {
      sec_user_name: props.authenticate.sec_user_name,
      site_id: event.target.options[event.target.selectedIndex].attributes.site_id.value,
      site_name: event.target.options[event.target.selectedIndex].attributes.site_name.value,
      sec_organization_id: event.target.options[event.target.selectedIndex].attributes.sec_organization_id.value,
    };
    // preserve the new site in Authenticate
    setAuthenticate((authenticate) => ({
      ...authenticate,
      [name]: value,
    }));
    getSiteChange(args);
  }

  if (!props.authenticate.sec_user_valid) {
    return <> </>;
  } else {
    return (
      <>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: "50%",
            zIndex: 10000,
          }}
        >
          <LoadingIndicator />
        </div>
        <div className="d-flex align-items-center navbarContainer">
          <Navbar
            collapseOnSelect
            expand="lg"
            style={{
              zIndex: 10,
              maxWidth: "1200px",
              width: "100vw",
            }}
          >
            <div className="d-lg-none align-items-left logoAlign">
              <img
                className="img-fluid"
                style={{
                  maxWidth: "12em",
                  alignSelf: "center",
                  msFlexItemAlign: "center",
                  msGridRowAlign: "center",
                  justifySelf: "end",
                  msGridColumnAlign: "end",
                  paddingRight: "1.4em",
                }}
                src={istLogo}
                alt="logo"
              />
            </div>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav" className="navbarCollapseMobile">
              <Nav justify variant="tabs" className="tabStyleMobile" defaultActiveKey="#/home">
                <Nav.Item className="d-none d-lg-block">
                  <Nav.Link href="#/home" className="nav-tab" style={{ paddingLeft: "1em", paddingRight: "1em" }}>
                    {homeIcon}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="d-lg-none">
                  <Nav.Link href="#/home" className="nav-tab" style={{ paddingLeft: "1em", paddingRight: "1em" }}>
                    Home
                  </Nav.Link>
                </Nav.Item>
                {props.menus
                  .filter((menu) => menu.navName !== "home")
                  .map((tmpMenu) => (
                    <Nav.Item key={tmpMenu.navName}>
                      <Nav.Link
                        href={`#/${tmpMenu.navName}`}
                        className="nav-tab"
                        style={{
                          paddingLeft: "1em",
                          paddingRight: "1em",
                        }}
                      >
                        {tmpMenu.tabName}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                <Nav.Item>
                  <Nav.Link
                    href=""
                    className="nav-tab"
                    style={{
                      display: "grid",
                      gridTemplateColumns: "50% 50%",
                      paddingLeft: "1em",
                      paddingRight: "1em",
                    }}
                  >
                    {authenticate.selected_site_name}
                    {/* {props.loading ? <LoadingIndicator /> : null} */}
                    <div
                      style={{
                        gridColumn: "1 / span 2",
                        justifySelf: "end",
                        msGridColumnAlign: "end",
                      }}
                    >
                      <LoadingIndicator />
                      <SiteListAlert
                        handleSiteChange={handleSiteChange}
                        sitesList={props.sitesList}
                        authenticate={authenticate}
                      />
                    </div>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <div
                    style={{
                      backgroundColor: "#343434",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Nav.Link
                      href=""
                      className="nav-tab"
                      style={{
                        paddingLeft: "1em",
                        paddingRight: "1em",
                      }}
                    >
                      {`Logged in as ${authenticate.sec_user_first_name}`}
                    </Nav.Link>
                    {loggingOut ? (
                      <div>
                        <LoadingIndicator />
                      </div>
                    ) : (
                      <div
                        to="/home"
                        style={{
                          alignSelf: "center",
                          msFlexItemAlign: "center",
                          msGridRowAlign: "center",
                        }}
                        data-tip={`Logged In as ${props.authenticate.sec_user_first_name}`}
                        data-type="light"
                        onClick={handleLogout}
                      >
                        {logoutIcon}
                        <ReactTooltip delayShow={50} />
                      </div>
                    )}
                  </div>
                </Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </>
    );
  }
};

HeaderMobile.propTypes = {
  authenticate: PropTypes.object,
  errors: PropTypes.any,
  getLogout: PropTypes.func.isRequired,
  getSiteChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  loggingOut: PropTypes.bool.isRequired,
  menus: PropTypes.array,
  sitesList: PropTypes.array,
};
SiteListAlert.propTypes = {
  authenticate: PropTypes.object.isRequired,
  sitesList: PropTypes.array.isRequired,
  handleSiteChange: PropTypes.func.isRequired,
};
function mapStateToProps(state) {
  const authenticate = state.authenticate;
  const sitesList = state.sitesList;
  const loggingOut = false;
  const menus = state.menus;
  return {
    authenticate,
    sitesList,
    loading: state.apiCallsInProgress > 0,
    loggingOut,
    menus,
  };
}

const mapDispatchToProps = {
  getLogout,
  getSiteChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMobile);
