export const managementFiles = {
  ARCHIVE_REPORT: "archiveReport",
  CONTRACT: "contractFiles",
  DELETE_REPORT: "deleteReport",
  INVOICE: "invoiceFiles",
  MANAGEMENT: "managementReports",
  MANAGEMENT_ANNOTATED: "managementReportsAnnotated",
  MANAGEMENT_ANNOTATED_ARCHIVED: "managementReportsAnnotatedArchived",
  MANAGEMENT_ARCHIVED: "managementReportsArchived",
  NEWS: "newsFiles",
  NEWS_ARCHIVED: "newsFilesArchived",
  SOP: "sops",
  SUPPLEMENTAL: "supplementalReports",
  SUPPLEMENTAL_ARCHIVED: "supplementalReportsArchived",
  SURVEY: "surveyFiles",
  QBR: "qbrFiles",
  QBR_ARCHIVED: "qbrFilesArchived",
  QBR_SUPPLEMENTAL: "qbrSupplementalReports",
  QBR_SUPPLEMENTAL_ARCHIVED: "qbrSupplementalReportsArchived"
};
export const managementLabels = {
  "Contract Files": managementFiles.CONTRACT,
  Invoices: managementFiles.INVOICE,
  Reports: managementFiles.MANAGEMENT,
  "Reports Annotated": managementFiles.MANAGEMENT_ANNOTATED,
  "Reports Anno. Archived": managementFiles.MANAGEMENT_ANNOTATED_ARCHIVED,
  "Reports Archived": managementFiles.MANAGEMENT_ARCHIVED,
  "Latest News": managementFiles.NEWS,
  "Archived News": managementFiles.NEWS_ARCHIVED,
  SOPs: managementFiles.SOP,
  "Supplemental Reports": managementFiles.SUPPLEMENTAL,
  "Supp. Reports Archived": managementFiles.SUPPLEMENTAL_ARCHIVED,
  "Survey Reports": managementFiles.SURVEY,
  "QBR Reports": managementFiles.QBR,
  "QBR Reports Archived": managementFiles.QBR_ARCHIVED,
  "QBR Supplemental Reports": managementFiles.QBR_SUPPLEMENTAL,
  "QBR Supplemental Reports Archived": managementFiles.QBR_SUPPLEMENTAL_ARCHIVED
};
