import { handleResponse, handleError } from "./apiUtils";

/* API for Service Calls */
let _token = "";

const END_USER_URL = process.env.CF_URL + "EndUserJS.cfc?";

/*  IE tends to cache content, so subsequent calls may return the same data. 
    This is intended as a cache breaker */
function getRandomStr() {
  return new Date().getTime().toString();
}

export function setToken(token) {
  _token = token + "&";
}

export function getServiceCalls(bShowAll) {
  let urlParams = JSON.stringify({
    bShowUserOnly: bShowAll,
  });

  return fetch(
    END_USER_URL +
      _token +
      "method=getServiceCallsJS&detailArg=" +
      btoa(unescape(encodeURIComponent(urlParams))) +
      "&randomStr=" +
      getRandomStr()
  )
    .then(handleResponse)
    .catch(handleError);
}

export function getMachines() {
  return fetch(END_USER_URL + _token + "method=getMachinesJS&randomStr=" + getRandomStr())
    .then(handleResponse)
    .catch(handleError);
}

export function addServiceCall(args) {
  let urlParams = JSON.stringify({
    machineId: args.machineId,
    machineLocation: args.machineLocation,
    problemReported: args.problemReported,
  });
  return fetch(
    END_USER_URL +
      _token +
      "method=addServiceCallJS&serviceCall=" +
      btoa(unescape(encodeURIComponent(urlParams))) +
      "&randomStr=" +
      getRandomStr()
  )
    .then(handleResponse)
    .catch(handleError);
}

export function getServiceCallDetails(serviceId) {
  let urlParams = JSON.stringify({
    service_id: serviceId,
  });
  return fetch(
    END_USER_URL +
      _token +
      "method=getServiceCallDetailsByIdJS&detailArg=" +
      btoa(unescape(encodeURIComponent(urlParams))) +
      "&randomStr=" +
      getRandomStr()
  )
    .then(handleResponse)
    .catch(handleError);
}
export function getMachineServiceHistory(serialnum) {
  let urlParams = JSON.stringify({
    serial_id: serialnum,
  });
  return fetch(
    END_USER_URL +
      _token +
      "method=getMachineServiceHistoryByIdJS&detailArg=" +
      btoa(unescape(encodeURIComponent(urlParams))) +
      "&randomStr=" +
      getRandomStr()
  )
    .then(handleResponse)
    .catch(handleError);
}
