import { combineReducers } from "redux";
import apiCallsInProgress from "./apiStatusReducer";
import authenticate from "./authenticationReducer";
import clientIsPhone from "./clientIsPhoneReducer";
import copyContent from "./copyContentReducer";
import createLogin from "./createLoginReducer";
import employeeProfile from "./employeeProfileReducer";
import equipment from "./equipmentReducer";
import equipmentMachineServiceHistory from "./equipmentMachineServiceHistoryReducer";
import equipmentServiceCallDetail from "./equipmentServiceCallDetailReducer";
import eula from "./eulaReducer";
import mailroomContent from "./mailroomContentReducer";
import mailroomContentDetail from "./mailroomContentDetailReducer";
import managementContent from "./managementContentReducer";
import menus from "./tabsAndMenusReducer";
import passwordReset from "./passwordResetReducer";
import recordsContent from "./recordsContentReducer";
import screenToggles from "./screenTogglesReducer";
import seniorManagement from "./seniorManagementReducer";
import siteManagement from "./siteManagementReducer";
import sitesList from "./sitesListReducer";
import teamContent from "./teamContentReducer";
import workOrder from "./workOrderReducer";
import workorderAttachments from "./workorderAttachmentsReducer";
import workorderInitialCookieValues from "./workorderInitialCookieValuesReducer";
import jotFormSubmitResult from "./jotFormReducer";
import changePwResult from "./changePasswordReducer";

// eslint-disable-next-line no-unused-vars
const lic = require("../../LICENSE"); // ensure license file is included when built by Mamboo

const rootReducer = combineReducers({
  apiCallsInProgress,
  authenticate,
  clientIsPhone,
  copyContent,
  createLogin,
  employeeProfile,
  equipment,
  equipmentMachineServiceHistory,
  equipmentServiceCallDetail,
  eula,
  mailroomContent,
  mailroomContentDetail,
  managementContent,
  menus,
  passwordReset,
  recordsContent,
  screenToggles,
  seniorManagement,
  siteManagement,
  sitesList,
  teamContent,
  workOrder,
  workorderAttachments,
  workorderInitialCookieValues,
  jotFormSubmitResult,
  changePwResult,
});

export default rootReducer;
