import { handleResponse, handleError } from "./apiUtils";
import _ from "lodash";

let _token = "";

const WORKORDER_URL = process.env.CF_URL + "WorkOrderJS.cfc?";
const END_USER_URL = process.env.CF_URL + "EndUserJS.cfc?";
const DOWNLOAD_FILE_URL = process.env.CF_URL + "show_report_json.cfm?";
export const BASE_URL = process.env.CF_URL;

/*  IE tends to cache content, so subsequent calls may return the same data. 
    This is intended as a cache breaker */
function getRandomStr() {
  return new Date().getTime().toString();
}

export function setToken(token) {
  _token = token + "&";
}

export function getWorkOrders(params, bIncludeSupportData = true) {
  // let urlParams = JSON.stringify({ bShowAll: params.bShowAll});
  let urlParams = JSON.stringify(params);
  let supportData = "";
  if (!bIncludeSupportData) {
    supportData = "&bIncludeSupportData=0";
  }
  return fetch(
    WORKORDER_URL +
      _token +
      "method=getWorkOrdersFilterData&args=" +
      btoa(unescape(encodeURIComponent(urlParams))) +
      supportData +
      "&randomStr=" +
      getRandomStr()
  )
    .then(handleResponse)
    .catch(handleError);
}

export function getWorkorderAttachments(params) {
  let urlParams = JSON.stringify({ workorder_id: params.workorder_id, site_id: params.site_id });
  return fetch(
    WORKORDER_URL +
      _token +
      "method=getWorkOrderAttachments&args=" +
      btoa(unescape(encodeURIComponent(urlParams))) +
      "&randomStr=" +
      getRandomStr()
  )
    .then(handleResponse)
    .catch(handleError);
}

export function deleteWorkOrderAttachment(params) {
  let formData = new FormData();
  formData.append("filenames", params.filenames);
  formData.append("file_names", params.file_names);
  formData.append("site_id", params.site_id);
  formData.append("workorder_id", params.workorder_id);
  return fetch(WORKORDER_URL + _token + "method=deleteWorkOrderAttachment&randomStr=" + getRandomStr(), {
    method: "POST",
    body: formData,
  })
    .then(handleResponse)
    .catch(handleError);
}

export function addUpdateWorkorder(params, workorderInitialCookieValues) {
  let _workorderInitialCookieValues = _.cloneDeep(workorderInitialCookieValues);
  _workorderInitialCookieValues.bShowAll = params.bShowAll;
  let formData = new FormData();
  formData.append("args", btoa(unescape(encodeURIComponent(JSON.stringify(params)))));
  formData.append("raw_filterData", btoa(unescape(encodeURIComponent(JSON.stringify(_workorderInitialCookieValues)))));
  return fetch(WORKORDER_URL + _token + "method=addUpdateWorkOrder&randomStr=" + getRandomStr(), {
    method: "POST",
    body: formData,
  })
    .then(handleResponse)
    .catch(handleError);
}

export function createSqlReport(params) {
  let urlParams = JSON.stringify(params);
  return fetch(
    END_USER_URL +
      _token +
      "method=printReportJS&contentVO=" +
      btoa(unescape(encodeURIComponent(urlParams))) +
      "&randomStr=" +
      getRandomStr()
  )
    .then(handleResponse)
    .catch(handleError);
}

export function openLocation(args) {
  // gets a file using show_report_json.cfm and the encrypted file name and path
  if (args.type === "path") {
    window.open(args.value + "?" + _token + "&randomStr=" + getRandomStr(), "_blank");
  } else if (args.type === "pdf") {
    // submit for report
    let _args = { type: "path", value: btoa(unescape(encodeURIComponent(args.location))) };
    window.open(
      DOWNLOAD_FILE_URL +
        _token +
        "args=" +
        btoa(unescape(encodeURIComponent(JSON.stringify(_args)))) +
        "&randomStr=" +
        getRandomStr(),
      "_blank"
    );
  }
}
