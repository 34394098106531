import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function serviceCallsReducer(state = initialState.equipment, action) {
  switch (action.type) {
    case types.GET_EQUIPMENT_SERVICE_CALLS_SUCCESS:
    case types.GET_EQUIPMENT_MACHINES_SUCCESS:
      return action.result;
    case types.CLEAR_EQUIPMENT:
      return initialState.equipment;
    default:
      return state;
  }
}
