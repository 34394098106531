import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Container, Row, Col, Navbar, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SiteSelectInput from "./SiteSelectInput";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import { getLogout, getSiteChange } from "../../redux/actions/authenticationActions";
import "../../assets/styles/index.css";
import LoadingIndicator from "../common/LoadingIndicator";
import "./Header.css";
import istLogo from "../../assets/images/ISTLogo.png";

const homeIcon = <FontAwesomeIcon icon="home" size="lg" style={{ color: "green" }} />;
const logoutIcon = <FontAwesomeIcon icon="power-off" size="lg" color="green" />;

export const Header = ({ getLogout, getSiteChange, errors = {}, ...props }) => {
  const [authenticate, setAuthenticate] = useState({
    ...props.authenticate,
  });
  useEffect(
    () => {
      setAuthenticate({ ...props.authenticate });
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.authenticate]
  );

  // call logout
  async function handleLogout() {
    ReactTooltip.hide();
    // navigate to home (who knows whether the select site has the active tab in its settings ...)
    props.history.location.pathname !== "/home" && props.history.push("/home");
    getLogout(authenticate).catch(() => {
      // console.log(error);
    });
  }

  // call site change
  function handleSiteChange(event) {
    const { name, value } = event.target;
    /* eslint-disable */
    const args = {
      sec_user_name: props.authenticate.sec_user_name,
      site_id: event.target.options[event.target.selectedIndex].getAttribute("data-site_id"),
      site_name: event.target.options[event.target.selectedIndex].getAttribute("data-site_name"),
      sec_organization_id: event.target.options[event.target.selectedIndex].getAttribute("data-sec_organization_id"),
    };
    /* eslint-enable */

    // preserve the new site in Authenticate
    setAuthenticate((prevAuthenticate) => ({
      ...prevAuthenticate,
      [name]: value,
    }));
    // navigate to home (who knows whether the select site has the active tab in its settings ...)
    props.history.location.pathname !== "/home" && props.history.push("/home");
    // manually set the active tab back to home since this navigation bypasses the tabs, which are not bound to the nav state, and just fires off change events
    let foo = document.getElementsByTagName("a");
    for (const obj of foo) {
      if (obj.hash === "#/home") {
        obj.className = "nav-tab nav-link active";
      } else {
        obj.className = "nav-tab nav-link";
      }
    }
    getSiteChange(args);
  }
  if (!props.authenticate.sec_user_valid) {
    return <> </>;
  } else {
    return (
      <>
        <div
          style={{
            position: "absolute",
            top: 50,
            left: "50%",
            zIndex: 10005,
          }}
        >
          <LoadingIndicator />
        </div>
        <Container fluid={true} className="navInactive containerStyle">
          <Row className="d-flex align-items-center rowStyle">
            <Col lg={true} className="d-none d-lg-block">
              <h1>My IST Support Center</h1>
            </Col>

            <Col lg={true} className="d-xs-none d-md-block ">
              <>
                <SiteSelectInput
                  disabled={props.loading}
                  name="selected_site_id"
                  label=""
                  value={authenticate.selected_site_id}
                  defaultOption="Select Site"
                  options={props.sitesList.map((site) => ({
                    value: site.site_id,
                    text: site.site_name,
                    sec_organization_id: site.sec_organization_id,
                    site_name: site.site_name,
                    site_id: site.site_id,
                  }))}
                  onChange={handleSiteChange}
                  error={errors.error}
                />
              </>
            </Col>
            <Col lg={true} className="d-none d-lg-block">
              <div className="logoAlign">
                <img className="img-fluid" src={istLogo} alt="logo" />
                <div
                  to="/home"
                  style={{
                    alignSelf: "center",
                    msFlexItemAlign: "center",
                    msGridRowAlign: "center",
                    cursor: "pointer",
                  }}
                  data-tip={`Logged In as ${props.authenticate.sec_user_first_name}`}
                  data-place="left"
                  data-type="light"
                  onClick={handleLogout}
                >
                  {logoutIcon}
                  <ReactTooltip delayShow={50} />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="d-flex align-items-center navbarContainer">
          <Navbar collapseOnSelect expand="lg" style={{ maxWidth: "1200px", width: "100vw" }}>
            <div className="d-lg-none align-items-left logoAlign">
              <img
                className="img-fluid"
                style={{
                  maxWidth: "20em",
                  alignSelf: "center",
                  msFlexItemAlign: "center",
                  msGridRowAlign: "center",
                  justifySelf: "end",
                  msGridColumnAlign: "right",
                  paddingRight: "1.4em",
                }}
                src={istLogo}
                alt="logo"
              />
              <div
                to="/home"
                style={{
                  alignSelf: "center",
                  msFlexItemAlign: "center",
                  msGridRowAlign: "center",
                }}
                data-tip={`Logged In as ${props.authenticate.sec_user_first_name}`}
                data-type="light"
                onClick={handleLogout}
              >
                {logoutIcon}
                <ReactTooltip delayShow={50} />
              </div>
            </div>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav justify variant="tabs" className="tabsStyle" defaultActiveKey="#/home">
                <Nav.Item className="d-none d-lg-block">
                  <Nav.Link href="#/home" className="nav-tab" style={{ paddingLeft: "1em", paddingRight: "1em" }}>
                    {homeIcon}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="d-lg-none">
                  <Nav.Link href="#/home" className="nav-tab" style={{ paddingLeft: "1em", paddingRight: "1em" }}>
                    Home
                  </Nav.Link>
                </Nav.Item>
                {props.menus
                  .filter((menu) => menu.navName !== "home")
                  .map((tmpMenu) => (
                    <Nav.Item key={tmpMenu.navName}>
                      <Nav.Link
                        href={`#/${tmpMenu.navName}`}
                        className="nav-tab"
                        style={{
                          paddingLeft: "1em",
                          paddingRight: "1em",
                        }}
                        onClick={() => {
                          props.setHasContent(false);
                        }}
                      >
                        {tmpMenu.tabName}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </>
    );
  }
};

Header.propTypes = {
  authenticate: PropTypes.object,
  errors: PropTypes.any,
  getLogout: PropTypes.func,
  getSiteChange: PropTypes.func,
  history: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  menus: PropTypes.array,
  setHasContent: PropTypes.func.isRequired,
  sitesList: PropTypes.array,
};

function mapStateToProps(state) {
  const authenticate = state.authenticate;
  const sitesList = state.sitesList;
  const menus = state.menus;
  const loading = state.apiCallsInProgress > 0;
  return {
    authenticate,
    sitesList,
    loading,
    menus,
  };
}

const mapDispatchToProps = {
  getLogout,
  getSiteChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
