import * as types from "./actionTypes";

function _clearCopyContent() {
  return { type: types.CLEAR_COPY_CONTENT };
}
function _clearEquipment() {
  return { type: types.CLEAR_EQUIPMENT };
}
function _clearEquipmentContent() {
  return { type: types.CLEAR_EQUIPMENT_CONTENT };
}
function _clearMachineServiceHistory() {
  return { type: types.CLEAR_EQUIPMENT_MACHINE_SERVICE_HISTORY };
}
function _clearMachineServiceDetail() {
  return { type: types.CLEAR_EQUIPMENT_SERVICE_CALL_DETAIL };
}
function _clearMailroomContent() {
  return { type: types.CLEAR_MAILROOM_CONTENT };
}
function _clearManagementContent() {
  return { type: types.CLEAR_MANAGEMENT_CONTENT };
}
function _clearMailroomContentDetail() {
  return { type: types.CLEAR_MAILROOM_CONTENT_DETAIL };
}
function _clearSiteManagement() {
  return { type: types.CLEAR_SITE_MANAGEMENT };
}
function _clearSiteSeniorManagement() {
  return { type: types.CLEAR_SITE_SENIOR_MANAGEMENT };
}
function _clearTeamContent() {
  return { type: types.CLEAR_TEAM_CONTENT };
}
function _clearRecords() {
  return { type: types.CLEAR_RECORDS_CONTENT };
}
function _clearWorkOrder() {
  return { type: types.CLEAR_WORKORDER };
}
function _clearWorkorderAttachments() {
  return { type: types.CLEAR_WORKORDER_ATTACHMENTS };
}
function _clearEmployeeProfile() {
  return { type: types.CLEAR_EMPLOYEE_PROFILE };
}
export function clearContent() {
  return function (dispatch) {
    dispatch(_clearCopyContent());
    dispatch(_clearEmployeeProfile());
    dispatch(_clearEquipment());
    dispatch(_clearEquipmentContent());
    dispatch(_clearMailroomContent());
    dispatch(_clearMailroomContentDetail());
    dispatch(_clearManagementContent());
    dispatch(_clearTeamContent());
    dispatch(_clearSiteManagement());
    dispatch(_clearSiteSeniorManagement());
    dispatch(_clearMachineServiceHistory());
    dispatch(_clearMachineServiceDetail());
    dispatch(_clearRecords());
    dispatch(_clearWorkOrder());
    dispatch(_clearWorkorderAttachments());
  };
}
export function clearCopyContent() {
  return function (dispatch) {
    dispatch(_clearCopyContent());
  };
}
export function clearEmployeeProfile() {
  return function (dispatch) {
    dispatch(_clearEmployeeProfile());
  };
}
export function clearEquipmentContent() {
  return function (dispatch) {
    dispatch(_clearEquipmentContent());
  };
}
export function clearMailroomContent() {
  return function (dispatch) {
    dispatch(_clearMailroomContent());
  };
}
export function clearMailroomContentDetail() {
  return function (dispatch) {
    dispatch(_clearMailroomContentDetail());
  };
}
export function clearManagementContent() {
  return function (dispatch) {
    dispatch(_clearManagementContent());
  };
}
export function clearTeamContent() {
  return function (dispatch) {
    dispatch(_clearTeamContent());
  };
}
export function clearWorkOrder() {
  return function (dispatch) {
    dispatch(_clearWorkOrder());
  };
}
