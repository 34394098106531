import * as _ from "lodash";

/* sortKeysBy orders the properties of the object by property name, first level only */
_.mixin({
  sortKeysBy: function (obj, comparator) {
    var keys = _.sortBy(_.keys(obj), function (key) {
      return comparator ? comparator(obj[key], key) : key;
    });
    return _.zipObject(
      keys,
      _.map(keys, function (key) {
        return obj[key];
      })
    );
  },
});
